import type { TSuggestionResponse } from '@tl-lms/types/option'
import algoliasearch from 'algoliasearch/lite'
function epoch(date: string) {
  return Date.parse(date)
}

export function useJobSearchSuggestions(queryRef: Ref<string | undefined>) {
  const runtimeConfig = useRuntimeConfig()
  const searchClient = algoliasearch(
    runtimeConfig.public.algoliaAppId,
    runtimeConfig.public.algoliaSearchKey
  )

  return useAsyncData<{
    queryHits: string[]
    jobHits: string[]
    companyHits: string[]
  }>(
    `job-search-suggestions--${JSON.stringify(queryRef.value)}`,
    async () => {
      if (!queryRef.value)
        return {
          queryHits: [],
          jobHits: [],
          companyHits: []
        }

      const now = Math.round(epoch(new Date().toISOString()) / 1000)

      // @ts-ignore: multipleQueries is not defined in algoliasearch
      const multiHits = await searchClient.multipleQueries([
        {
          // Job matches
          indexName: 'jobs',
          query: queryRef.value,
          params: {
            hitsPerPage: 2,
            attributesToRetrieve: ['title', 'company__name'],
            filters: `company__line_of_business:${runtimeConfig.public.lineOfBusiness} AND company__region_of_business:${runtimeConfig.public.regionOfBusiness} AND ready_to_publish:true`,
            numericFilters: [`posting_date <= ${now}`, `closing_date > ${now}`]
          }
        },
        {
          // Company Matches
          indexName: 'jobs',
          query: queryRef.value,
          type: 'facet',
          facet: 'company__name',
          params: {
            hitsPerPage: 2
          }
        },
        {
          // Query Suggestions
          indexName: 'jobs_query_suggestions',
          query: queryRef.value,
          params: {
            hitsPerPage: 3
          }
        },
        {
          // Keywords
          indexName: 'jobs',
          query: queryRef.value,
          type: 'facet',
          facet: 'keywords',
          params: {
            hitsPerPage: 5
          }
        },
        {
          // Keywords
          indexName: 'jobs',
          query: queryRef.value,
          type: 'facet',
          facet: 'ai_keywords',
          params: {
            hitsPerPage: 5
          }
        }
      ])

      let queryHits = multiHits.results[2].hits.map((h: any) => h.query)

      if (multiHits.results[3].facetHits) {
        queryHits = queryHits.concat(multiHits.results[3].facetHits.map((h: any) => h.value))
      }
      if (multiHits.results[4].facetHits) {
        queryHits = queryHits.concat(multiHits.results[4].facetHits.map((h: any) => h.value))
      }

      const companyHits = multiHits.results[1].facetHits.map((h: any) => h.value)
      const jobHits = multiHits.results[0].hits.map((h: any) => `${h.title}, ${h.company__name}`)

      return {
        queryHits: queryHits.slice(0, 5),
        companyHits: companyHits.slice(0, 5),
        jobHits: jobHits.slice(0, 5)
      }
    },
    {
      watch: [queryRef],
      lazy: true
    }
  )
}

export function useSuggestions(
  endpoint: string,
  queryRef: Ref<string | undefined>
): ReturnType<typeof useFetch<TSuggestionResponse>> {
  const runtimeConfig = useRuntimeConfig()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  return useFetch(endpoint, {
    baseURL,
    method: 'GET',
    query: {
      search_query: queryRef,
      is_detailed: false
    },
    server: false, // No need to fetch on server side
    watch: [queryRef] // refresh on query update
  })
}

export function useSuggestionsOnce(
  endpoint: string,
  queryRef: string
): Promise<TSuggestionResponse> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  return $fetch(endpoint, {
    baseURL,
    method: 'GET',
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    },
    query: {
      search_query: queryRef,
      is_detailed: false
    }
  }) as Promise<TSuggestionResponse>
}
